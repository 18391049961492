import * as React from "react";
import ComponentSelection from "../ComponentSelection";
import Desktop from "../desktop/Desktop";
import "../../styles/main.css";
import Create_Button from "../Create_Button";
import { useEffect, useState } from "react";
import { Custom_Build_Input } from "../../backend-generated-types";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Button, Fade, Grid, TextField, Typography } from "@mui/material";
import { Dayjs } from "dayjs";
import {
  heading_styles,
} from "../../styles/Add_Build_Styles";
import {
  USAGE_BROWSING_STRING,
  USAGE_ENGINEERING_STRING,
  USAGE_GAMING_STRING,
  USAGE_MEDIA_EDITING_STRING,
  USAGE_NONE_STRING,
  USAGE_SOFTWARE_DEV_STRING,
  USAGE_UNKNOWN_STRING,
} from "../../constants";
import { navigate } from "gatsby";

type Props = {
  date_of_purchase?: Dayjs;
  selected_usage_options?: string[];
};

const name_container_style = {
  padding: 20,
  width: 300,
  margin: "auto",
};

// also found in backend's test/utils/graphql_custom_builds
function build_object_to_string_helper(
  build_object?: Custom_Build_Input
): string {
  if (build_object) {
    return `{
            name: "${build_object.name}",
            ${build_object.cpu_ids?.length
        ? `cpu_ids: [${build_object.cpu_ids.map(
          (id) => '"' + id + '",'
        )}],`
        : ``
      }
            ${build_object.gpu_ids?.length
        ? `gpu_ids: [${build_object.gpu_ids.map(
          (id) => '"' + id + '",'
        )}],`
        : ``
      }
            ${build_object.psu_ids?.length
        ? `psu_ids: [${build_object.psu_ids.map(
          (id) => '"' + id + '",'
        )}],`
        : ``
      }
            ${build_object.cpu_cooler_ids?.length
        ? `cpu_cooler_ids: [${build_object.cpu_cooler_ids.map(
          (id) => '"' + id + '",'
        )}],`
        : ``
      }
            ${build_object.operating_system_ids?.length
        ? `operating_system_ids: [${build_object.operating_system_ids.map(
          (id) => '"' + id + '",'
        )}],`
        : ``
      }
            ${build_object.storage_ids?.length
        ? `storage_ids: [${build_object.storage_ids.map(
          (id) => '"' + id + '",'
        )}],`
        : ``
      }
            ${build_object.case_id ? `case_id: "${build_object.case_id}",` : ``}
            ${build_object.motherboard_id
        ? `motherboard_id: "${build_object.motherboard_id}",`
        : ``
      }
            ${build_object.case_fan_ids?.length
        ? `case_fan_ids: [${build_object.case_fan_ids.map(
          (id) => '"' + id + '",'
        )}],`
        : ``
      }
            ${build_object.ram_ids?.length
        ? `ram_ids: [${build_object.ram_ids.map(
          (id) => '"' + id + '",'
        )}],`
        : ``
      }
            ${build_object.purchase_date
        ? `purchase_date: "${build_object.purchase_date}",`
        : ``
      }
            ${build_object.usage_browsing
        ? `usage_browsing: ${build_object.usage_browsing},`
        : ``
      }
            ${build_object.usage_engineering
        ? `usage_engineering: ${build_object.usage_engineering},`
        : ``
      }
            ${build_object.usage_gaming
        ? `usage_gaming: ${build_object.usage_gaming},`
        : ``
      }
            ${build_object.usage_media_editing
        ? `usage_media_editing: ${build_object.usage_media_editing},`
        : ``
      }
            ${build_object.usage_none
        ? `usage_none: ${build_object.usage_none},`
        : ``
      }
            ${build_object.usage_software_dev
        ? `usage_software_dev: ${build_object.usage_software_dev},`
        : ``
      }
            ${build_object.usage_unknown
        ? `usage_unknown: ${build_object.usage_unknown},`
        : ``
      }
        }`;
  }
  return "{}";
}

const Add_Build_Details = (props: Props) => {
  const [is_logged_in, set_logged_in] = useState(false);
  const [name, set_name] = useState("Untitled Build");
  const [cpu_ids, set_cpu_ids] = useState<string[]>([]);
  const [gpu_ids, set_gpu_ids] = useState<string[]>([]);
  const [psu_ids, set_psu_ids] = useState<string[]>([]);
  const [case_ids, set_case_ids] = useState<string[]>([]);
  const [case_fan_ids, set_case_fan_ids] = useState<string[]>([]);
  const [cpu_cooler_ids, set_cpu_cooler_ids] = useState<string[]>([]);
  const [motherboard_ids, set_motherboard_ids] = useState<string[]>([]);
  const [operating_system_ids, set_operating_system_ids] = useState<string[]>(
    []
  );
  const [ram_ids, set_ram_ids] = useState<string[]>([]);
  const [storage_ids, set_storage_ids] = useState<string[]>([]);
  const [build_object, set_build_object] = useState<Custom_Build_Input>();
  const [is_create_button_disabled, set_create_button_disabled] =
    useState(true);

  useEffect(() => {
    set_create_button_disabled(!is_logged_in || no_components_selected());
  }, [
    is_logged_in,
    cpu_ids,
    gpu_ids,
    psu_ids,
    case_ids,
    case_fan_ids,
    cpu_cooler_ids,
    motherboard_ids,
    operating_system_ids,
    ram_ids,
    storage_ids,
  ]);

  const { data: me_data, loading: me_loading } = useQuery(
    gql`
      query Login_Check {
        me {
          id
        }
      }
    `,
    {
      fetchPolicy: "cache-first",
    }
  );

  const [
    user_custom_build_mutation,
    {
      data: custom_build_data,
      loading: custom_build_loading,
    },
  ] = useMutation(gql`
        mutation Create_Custom_Build {
            custom_build(build: ${build_object_to_string_helper(
    build_object
  )}) {
                id
                name
                cpus {
                    id
                    base_name
                }
                gpus {
                    id
                    base_name
                }
                psus {
                    id
                    base_name
                }
                case {
                    id
                    base_name
                }
                case_fans {
                    id
                    base_name
                }
                cpu_coolers {
                    id
                    base_name
                }
                motherboard {
                    id
                    base_name
                }
                operating_systems {
                    id
                    base_name
                }
                storages {
                    id
                    base_name
                }
                rams {
                    id
                    base_name
                }
                latest_appraisal_price
            }
        }
    `);

  function no_components_selected(): boolean {
    return (
      !cpu_ids.length &&
      !gpu_ids.length &&
      !psu_ids.length &&
      !cpu_cooler_ids.length &&
      !case_ids.length &&
      !case_fan_ids.length &&
      !motherboard_ids.length &&
      !operating_system_ids.length &&
      !ram_ids.length &&
      !storage_ids.length
    );
  }

  function handle_submission() {
    if (name && !no_components_selected()) {
      set_build_object({
        name,
        cpu_ids: cpu_ids.length ? cpu_ids : undefined,
        gpu_ids: gpu_ids.length ? gpu_ids : undefined,
        psu_ids: psu_ids.length ? psu_ids : undefined,
        cpu_cooler_ids: cpu_cooler_ids.length ? cpu_cooler_ids : undefined,
        case_id: case_ids.length ? case_ids.at(0) : undefined,
        motherboard_id: motherboard_ids.length
          ? motherboard_ids.at(0)
          : undefined,
        operating_system_ids: operating_system_ids.length
          ? operating_system_ids
          : undefined,
        storage_ids: storage_ids.length ? storage_ids : undefined,
        ram_ids: ram_ids.length ? ram_ids : undefined,
        case_fan_ids: case_fan_ids.length ? case_fan_ids : undefined,
        purchase_date: props.date_of_purchase?.toISOString().substring(0, 10),
        usage_browsing: props.selected_usage_options?.includes(
          USAGE_BROWSING_STRING
        )
          ? 1.0
          : 0.0,
        usage_engineering: props.selected_usage_options?.includes(
          USAGE_ENGINEERING_STRING
        )
          ? 1.0
          : 0.0,
        usage_gaming: props.selected_usage_options?.includes(
          USAGE_GAMING_STRING
        )
          ? 1.0
          : 0.0,
        usage_media_editing: props.selected_usage_options?.includes(
          USAGE_MEDIA_EDITING_STRING
        )
          ? 1.0
          : 0.0,
        usage_none: props.selected_usage_options?.includes(USAGE_NONE_STRING)
          ? 1.0
          : 0.0,
        usage_software_dev: props.selected_usage_options?.includes(
          USAGE_SOFTWARE_DEV_STRING
        )
          ? 1.0
          : 0.0,
        usage_unknown: props.selected_usage_options?.includes(
          USAGE_UNKNOWN_STRING
        )
          ? 1.0
          : 0.0,
      } as Custom_Build_Input);
    }
  }

  useEffect(() => {
    if (me_data && !me_loading) {
      set_logged_in(!!me_data.me?.id);
    }
  }, [me_data, me_loading]);

  useEffect(() => {
    if (build_object) {
      user_custom_build_mutation();
    }
  }, [build_object]);

  useEffect(() => {
    if (!custom_build_loading && !!custom_build_data?.custom_build) {
      navigate(`/builds/${custom_build_data.custom_build.id}?welcome`);
    }
  }, [custom_build_loading]);

  return (
    <Fade in timeout={800} mountOnEnter unmountOnExit>
      <div> {/*Need an extra div here for Fade to work correctly. */}
        <Grid container>
          <Button
            variant={"contained"}
            style={{ position: "fixed", zIndex: 100, right: 20 }}
            href={"/manual/how-to-find-desktop-pc-components"}
            target="_blank"
          >
            Where do I find my components?
          </Button>
          <Grid item xs={12} m={2}>
            <h1 style={heading_styles}>
              Tell us about your PC.
              <div style={name_container_style}>
                <TextField
                  sx={{
                    "& ::placeholder": {
                      fontStyle: "italic",
                    },
                  }}
                  size="medium"
                  label="Your build's name"
                  variant="outlined"
                  placeholder={"The Bit Consumer 3000"}
                  onChange={(e) => {
                    set_name(e.target.value);
                  }}
                  fullWidth
                />
              </div>
            </h1>
            <Typography sx={{ textAlign: 'center' }}>Leave blank any parts you do not want to include in your build. You can edit all of this in your Garage later.</Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Grid container justifyContent={'center'}>
              <ComponentSelection
                name={"CPU"}
                query_type="cpu"
                placeholder={"Intel Core i9"}
                value_handler={set_cpu_ids}
                enable_multiple={true}
              />
              <ComponentSelection
                name={"CPU Cooler"}
                query_type="cpu_cooler"
                placeholder={"Noctua NH-L9i"}
                value_handler={set_cpu_cooler_ids}
                enable_multiple={true}
              />
              <ComponentSelection
                name={"GPU"}
                query_type="gpu"
                placeholder={"RTX 2070"}
                value_handler={set_gpu_ids}
                enable_multiple={true}
              />
              <ComponentSelection
                name={"Motherboard"}
                query_type="motherboard"
                placeholder={"EVGA Z790"}
                value_handler={set_motherboard_ids}
              />
              <ComponentSelection
                name={"Power Supply"}
                query_type="psu"
                placeholder={"Rosewill CFZ"}
                value_handler={set_psu_ids}
                enable_multiple={true}
              />
              <ComponentSelection
                name={"Case"}
                query_type="case"
                placeholder={"NZXT H710"}
                value_handler={set_case_ids}
              />
              <ComponentSelection
                name={"Hard Drive"}
                query_type="storage"
                placeholder={"Western Digital Blue"}
                value_handler={set_storage_ids}
                enable_multiple={true}
              />
              <ComponentSelection
                name={"Case Fan"}
                query_type="case_fan"
                placeholder={"Corsair ML Elite"}
                value_handler={set_case_fan_ids}
                enable_multiple={true}
              />
              <ComponentSelection
                name={"Operating System"}
                query_type="operating_system"
                placeholder={"Windows 11 Home"}
                value_handler={set_operating_system_ids}
                enable_multiple={true}
              />
              <ComponentSelection
                name={"RAM"}
                query_type="ram"
                placeholder={"G.Skill Aegis 16 GB"}
                value_handler={set_ram_ids}
                enable_multiple={true}
              />
            </Grid>
          </Grid>
          <Grid item lg={6} md={6} sm={12} justifyContent={'center'} sx={{ display: { xs: "none", sm: "flex" } }}>
            <Grid container justifyContent={'center'}>
              <Desktop
                is_static={false}
                case_enabled={!!case_ids.length}
                cpu_enabled={!!cpu_ids.length}
                cpu_cooler_enabled={!!cpu_cooler_ids.length}
                gpu_enabled={!!gpu_ids.length}
                motherboard_enabled={!!motherboard_ids.length}
                operating_system_enabled={!!operating_system_ids.length}
                psu_enabled={!!psu_ids.length}
                ram_enabled={!!ram_ids.length}
                storage_enabled={!!storage_ids.length}
                case_fan_enabled={!!case_fan_ids.length}
              />
              <Typography
                fontStyle={"italic"}
                fontSize={"small"}
                sx={{ textAlign: "right" }}
              >
                * Rendering for artistic purposes only; not accurate to product.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} justifyContent={'center'} sx={{ textAlign: 'center' }}>
            <Create_Button
              handle_submission={() => handle_submission()}
              disabled={is_create_button_disabled}
              loading={custom_build_loading}
            />
          </Grid>
        </Grid>
      </div >
    </Fade >
  );
};

export default Add_Build_Details;
